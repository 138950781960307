















































































































































































































































































































































#broadcast-report {
  .flatpickr-input {
    background: #FFF !important;
  }
}
.engagement-wrapper {
  i {
    font-size: 18px;
    cursor: pointer;
  }
}
